<template>
	<div >
		<div class="ft20 cl-black cl-main ftw500">消费记录</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<!-- <a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item> -->

					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					<a-form-item label="操作日期">
						<a-range-picker @change="changeOperateTime" v-model="search.operate_time" valueFormat="YYYY-MM-DD" />
					</a-form-item>
					<a-form-item label="日期">
						<a-radio-group v-model="search.time" >
						        <a-radio-button :value="0">
						          全部
						        </a-radio-button>
						        <a-radio-button :value="1">
						          今日
						        </a-radio-button>
								<a-radio-button :value="2">
								  昨日
								</a-radio-button>
								<a-radio-button :value="3">
								  本月
								</a-radio-button>
						  </a-radio-group>
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class=" " style="min-height: 500px;">
				<div class="wxb-table-white">
					<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
						<!-- <div class="text-center" slot="mendian" slot-scope="mendian,record">
							{{mendian ? mendian.name : '-'}}
						</div> -->
						
						<div class="flex alcenter center" slot="timeLong" slot-scope="timeLong,record">
							{{ getTimeDifference(record.begin_time,record.end_time) }}
						</div>
						<!-- <div class="text-center" slot="operator" slot-scope="record">
							<div v-if="record.operate_name && record.operate_mobile">
								<div class="ft14 cl-info">{{record.operate_name}}</div>
								<div class="ft12 cl-notice">({{record.operate_mobile}})</div>
							</div>
							<div v-else>-</div>
						</div> -->
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from'moment'
	import { listMixin } from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				search: {
					// nick_name: '',
					time: 0,
					mobile: "",
					operate_time:['',''],
				},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '用户昵称',dataIndex: 'nick_name',align: 'center'},
					{title: '单号',dataIndex: 'number_id',align: 'center'},
					{title: '手机号',dataIndex: 'mobile',align: 'center'},
					{title: '车牌号',dataIndex: 'car_number',align: 'center'},
					{ 	
						title: '付款方式',
						dataIndex: 'use_type',
						customRender:(text,row,index)=>{
							return text==1?'余额':text==2?'次卡':'-'
						}
					},
					{ 	
						title: '入场',
						dataIndex: 'begin_time',
						key: 'begin_time',
						customRender:(text,row,index)=>{
							return text?moment(text*1000).format('YYYY-MM-DD HH:mm:ss'):'-'
						}
					},
					{ 	
						title: '出场',
						dataIndex: 'end_time',
						key: 'end_time',
						customRender:(text,row,index)=>{
							return text?moment(text*1000).format('YYYY-MM-DD HH:mm:ss'):'-'
						}
					},
					{ title: '洗车时长',dataIndex: 'timeLong',align: 'center',scopedSlots: {customRender: 'timeLong'}},
					{ 	
						title: '支付时间',
						dataIndex: 'pay_time',
						key: 'pay_time',
						customRender:(text,row,index)=>{
							return text?moment(text*1000).format('YYYY-MM-DD HH:mm:ss'):'-'
						}
					},
					{ 	
						title: '应付金额',
						dataIndex: 'price',
						key: 'price',
						customRender:(text,row,index)=>{
							return text?text:'-'
						}
					},
					{ 	
						title: '实付金额',
						dataIndex: 'real_price',
						key: 'real_price',
						customRender:(text,row,index)=>{
							return text?text:'-'
						}
					},
				],
				datas: [],
				
			}
		},
		methods: {
			getTimeDifference(start, end) {
				if(end){
					const diff = end*1000 - start*1000; // 计算时间差的毫秒数
					// 将毫秒转换为小时、分钟和秒
					const hours = Math.floor(diff / 3600000);
					const minutes = Math.floor((diff % 3600000) / 60000);
					const seconds = Math.floor((diff % 60000) / 1000);
					return `${hours}时${minutes}分${seconds}秒`
				}else{
					return '未离场'
				}
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				let params = {
					time:this.search.time,
					operate_time:this.search.operate_time,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}
				if(this.search.mobile){
					params.mobile = this.search.mobile
				}
				this.$http.api('admin/order_list',params).then(res=>{
					this.pagination.total=res.count;
					this.datas=res.res;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			changeOperateTime(value){
				this.search.operate_time = value;
			},
			
			searchCancel() {
				this.search = {
					nick_name: '',
					mobile: "",
					operate_time:['',''],
				};
			},
			searchAct(){
				this.getLists();
			},
			cancelAct(){
				this.searchCancel();
				this.searchAct();
			}
		}
	}
</script>

<style>
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
		min-height: 700px;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
	
	.member-money-recharge-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>
